import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {Translate, withLocalize} from 'react-localize-redux';
import { connect } from 'react-redux';
import {Alert, Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row} from 'reactstrap';
import {FieldArray, Formik} from "formik";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";

import { isEmptyString, isGroupValid } from '../../../../../utils/string-utils';
import {STATUS_LIVE, STATUS_OFF, STATUS_ON} from "../../../../../constants";
import {lostStreamModePropTypes} from "../../../../../utils/models-prop-types";
import {getAvailableLostStreamModes, getAvailableNDIOutputStandards} from "../../../../../misc/capabilities.selectors";
import HelpLayout from '../../../../common/help-layout';
import CustomPatternForm from '../../../settings/custom-pattern/custom-pattern-form';
import {SETTINGS_NDI_OUTPUT_CUSTOM_PATTERN_PREFIX} from '../../../../../constants'
import {getNDIOutputNames} from "../../../../../misc/config.selectors";

const CUSTOM_PATTERN_VALUE = 3;

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  forbiddenNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  outputId: PropTypes.string.isRequired,
  outputStatus: PropTypes.oneOf([STATUS_LIVE, STATUS_OFF, STATUS_ON]),
  config: PropTypes.shape({
    name: PropTypes.string.isRequired,
    outputStandard: PropTypes.string.isRequired,
    sameAsInput: PropTypes.bool.isRequired,
    lostStreamMode: PropTypes.number.isRequired,
    lostStreamPolicy: PropTypes.bool,
    lostPatternMode: PropTypes.number.isRequired,
    enableDisplayName: PropTypes.bool,
    groups: PropTypes.string
  }),
  lostStreamModes: PropTypes.arrayOf(lostStreamModePropTypes).isRequired,
  outputStandards: PropTypes.arrayOf(PropTypes.string).isRequired
};

class GeneralForm extends Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCustomPattern = this.handleCustomPattern.bind(this);

    this.state = {
      customPatternFormVisible: false
    }
  }

  componentDidMount(){
    if(this.props.config.lostStreamMode === CUSTOM_PATTERN_VALUE || this.props.config.lostPatternMode === CUSTOM_PATTERN_VALUE){
      this.handleCustomPattern(true);
    }
  }

  handleCustomPattern(display){
    this.setState({
      customPatternFormVisible: display
    });
  }

  handleSubmit(values, {resetForm}) {
    const { groups, ...otherProps } = values;
    otherProps.groups = groups.join(',');

    otherProps.lostStreamMode = parseInt(values.lostStreamMode)
    otherProps.lostPatternMode = parseInt(values.lostPatternMode)
    this.props.onSubmit(otherProps);

    resetForm(values);
  }

  handleValidation = (values) => {
    const errors = {};
    if (isEmptyString(values.name)) {
      errors.name = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(this.props.forbiddenNames.indexOf(values.name) !== -1){
      errors.name = 'genericLabel.DUPLICATED_VALUES.text';
    }
    if (values.name.length > 24) {
      errors.name = 'genericLabel.TOO_LONG.text';
    }


    // AudioStandard
    if(isEmptyString(values.audioStandard)){
      errors.audioStandard = 'genericLabel.REQUIRED_FIELD.text';
    }

    // Groups
    values.groups.forEach((group, index) => {
      if(!errors.groups){
        errors.groups = [];
      }
      if(isEmptyString(group)){
        errors.groups[index] = 'genericLabel.REQUIRED_FIELD.text';
      }
      else if (!isGroupValid(group)) {
        errors.groups[index] = 'genericLabel.INVALID_FORMAT.text';
      }
      // Remove unused groups property from erros to allow submission
      if (errors.groups.length === 0) {
        delete(errors.groups)
      }
    })
    return errors;
  };

  render() {
    const props = this.props;
    const {translate, config, outputStatus, outputStandards, lostStreamModes, outputId, NDIAudioStandards} = props;
    return (
      <Formik initialValues={{
                name: config.name,
                streamName: config.streamName,
                outputStandard: config.outputStandard,
                sameAsInput: config.sameAsInput,
                lostStreamMode: `${config.lostStreamMode}`, // We transform to string here so that 'dirty' prop works
                lostStreamPolicy: config.lostStreamPolicy,
                lostPatternMode: `${config.lostPatternMode}`,
                enableDisplayName: config.enableDisplayName,
                groups: config && config.groups ? config.groups.split(',') : [],
                audioStandard: config && config.audioStandard ? config.audioStandard : '',
              }}
              validate={this.handleValidation}
              validateOnBlur={false}
              validateOnChange={true}
              onSubmit={this.handleSubmit}>
        {({
            values,
            errors,
            dirty,
            touched,
            handleChange,
            handleBlur,
            handleSubmit
            /* and other goodies */
          }) => (
          <Form className="output-settings-form"
                onSubmit={handleSubmit}>
            <HelpLayout filename="c_sh_ndi_output_settings.html"
              form={<Fragment>

                {outputStatus !== STATUS_OFF &&
                <Alert color="warning">
                  <Translate id="genericLabel.WARNING_LOCAL_OUTPUT.text"/>
                </Alert>
                }

                <FormGroup>
                  <Label for="name">
                    <Translate id="genericLabel.NAME.text"/>
                  </Label>
                  <Input type="text"
                         required
                         name="name"
                         id="output_ndiSetting_name"
                         invalid={errors.name !== undefined}
                         placeholder={translate("genericLabel.NAME.text")}
                         value={values.name}
                         onBlur={handleBlur}
                         onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.name}/>
                  </FormFeedback>
                  <div className="indicator">
                    <Translate id={`genericLabel.NAME_DISPLAYED_ON_PREVIEW_AND_OUTPUT.text`}/>
                  </div>
                </FormGroup>

                <FormGroup>
                  <Label for="streamName">
                    <Translate id="genericLabel.NDI_STREAM_NAME.text"/>
                  </Label>
                  <Input type="text"
                         required
                         name="streamName"
                         id="output_ndiSetting_streamName"
                         invalid={errors.streamName !== undefined}
                         placeholder={translate("genericLabel.NDI_STREAM_NAME.text")}
                         value={values.streamName}
                         onBlur={handleBlur}
                         onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.streamName}/>
                  </FormFeedback>
                </FormGroup>

                <FormGroup>
                  <Label for="outputStandard">
                    <Translate id="genericLabel.OUTPUT_STANDARD.text"/>
                  </Label>
                  <Input type="select"
                         name="outputStandard"
                         id="output_ndiSetting_outputStandard"
                         onBlur={handleBlur}
                         onChange={handleChange}
                         value={values.outputStandard}>
                    {outputStandards.map(standard => {
                      return <option key={standard} value={standard}>{standard}</option>
                    })}
                  </Input>
                </FormGroup>

                <FormGroup check>
                  <Label check>
                    <Input type="checkbox"
                           name="sameAsInput"
                           id="output_ndiSetting_sameAsInput"
                           onBlur={handleBlur}
                           onChange={handleChange}
                           checked={values.sameAsInput}/>{' '}
                    <Translate id="genericLabel.SAME_AS_CAMERA_VIDEO_STANDARD.text"/>
                  </Label>
                </FormGroup>

                {parseInt(values.lostStreamMode) !== 0 &&
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox"
                           name="enableDisplayName"
                           id="output_ndiSetting_enableDisplayName"
                           onChange={handleChange}
                           checked={values.enableDisplayName}/>{' '}
                    <Translate id="genericLabel.DISPLAY_OUTPUT_NAME.text"/>
                  </Label>
                </FormGroup>
                }

                { NDIAudioStandards.length !==0 &&
                <FormGroup>
                  <Label for="audioStandard">
                    <Translate id="genericLabel.AUDIO_STANDARD.text"/>
                  </Label>
                  <Input type="select"
                          name="audioStandard"
                          id="output_ndiSetting_audioStandard"
                          invalid={errors.audioStandard !== undefined}
                          value={values.audioStandard}
                          onChange={handleChange}>
                            <option  value=''></option>
                    { NDIAudioStandards.map((standard, key) => (
                      <option key={standard.name} value={standard.name}>{ standard.name }</option>
                    ))}
                  </Input>
                  <FormFeedback>
                    <Translate id={errors.audioStandard} />
                  </FormFeedback>
                </FormGroup>}
                <FormGroup>
                  <Label>
                    <Translate id="genericLabel.NDI_GROUPS.text" />
                    <div className="indicator">
                      <Translate id={`genericLabel.NDI_GROUPS_HELP.text`}/>
                    </div>
                  </Label>
                  <FieldArray name="groups"
                              validateOnChange={false}>
                    {({ push, remove }) => {
                      return (
                        <Fragment>
                          { values.groups.map((group, index) => (
                            <Row key={index}
                                form>
                              <Col xs={10}>
                                <FormGroup>
                                  <Input type="text"
                                        name={`groups[${index}]`}
                                        id={"output_ndiSetting_groups_"+index}
                                        invalid={errors.groups && errors.groups[index] !== undefined}
                                        onChange={handleChange}
                                        value={values.groups[index]}/>
                                  <FormFeedback>
                                    <Translate id={errors.groups && errors.groups[index]}/>
                                  </FormFeedback>
                                </FormGroup>
                              </Col>
                              <Col xs={2}>
                                { values.groups.length > 0 &&
                                <Button id={"output_ndiSetting_groups_"+index+"_deleteButton"}
                                        className="basic"
                                        onClick={() => remove(index)}>
                                  <AWIcon name="delete"/>
                                </Button>
                                }
                              </Col>
                            </Row>
                          ))}
                          <div>
                            <Button id="output_ndiSetting_addGroupsButton"
                                    disabled={values.groups.length >= 12}
                                    onClick={() => push('')}>
                              <Translate id="genericLabel.ADD_GROUP.text"/>
                            </Button>
                          </div>
                        </Fragment>
                      )}}
                  </FieldArray>
                </FormGroup>

                <FormGroup>
                  <Label for="lostStreamMode">
                    <Translate id="genericLabel.DEFAULT_VIDEO_PATTERN.text"/>
                  </Label>
                  <Input type="select"
                         name="lostStreamMode"
                         id="output_ndiSetting_lostStreamMode"
                         onBlur={handleBlur}
                         onChange={(e) => {
                           handleChange(e);
                           if (parseInt(e.target.value) !== CUSTOM_PATTERN_VALUE && parseInt(values.lostPatternMode) !== CUSTOM_PATTERN_VALUE) {
                             this.handleCustomPattern(false);
                           }
                           else {
                            this.handleCustomPattern(true);
                           }
                         }}
                         value={values.lostStreamMode}>
                    {lostStreamModes.map(dest => {
                      return <option key={dest.value}
                                     value={dest.value}
                                     id={translate(`lostStreamMode.${dest.name}.text`)}>{translate(`lostStreamMode.${dest.name}.text`)}</option>
                    })}
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label for="lostPatternMode">
                    <Translate id="genericLabel.POLICY_VIDEO_PATTERN.text"/>
                  </Label>
                  <Input type="select"
                         name="lostPatternMode"
                         id="output_ndiSetting_lostPatternMode"
                         onBlur={handleBlur}
                         onChange={(e) => {
                           handleChange(e);
                           if (parseInt(e.target.value) !== CUSTOM_PATTERN_VALUE && parseInt(values.lostStreamMode) !== CUSTOM_PATTERN_VALUE) {
                            this.handleCustomPattern(false);
                           }
                           else {
                            this.handleCustomPattern(true);
                           }
                         }}
                         value={values.lostPatternMode}>
                    {lostStreamModes.map(dest => {
                      return <option key={dest.value}
                                     value={dest.value}
                                    id={translate(`lostStreamMode.${dest.name}.text`)}>{translate(`lostStreamMode.${dest.name}.text`)}</option>
                    })}
                  </Input>
                </FormGroup>


                { this.state.customPatternFormVisible &&
                  <CustomPatternForm prefix={SETTINGS_NDI_OUTPUT_CUSTOM_PATTERN_PREFIX} id={outputId} timestamp={new Date().getTime()}/>
                }
              </Fragment>}
              buttons={
                <FormGroup className="buttons">
                  <Button id="output_ndiSetting_saveButton"
                          color="primary"
                          disabled={!dirty}
                          type="submit">
                    <Translate id="genericLabel.SAVE.text"/>
                  </Button>
                </FormGroup>
              } />
          </Form>
        )}
      </Formik>
    );
  }
}

GeneralForm.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  const existingNDIOutputNames = getNDIOutputNames(state);
  return {
    outputStatus: state.streamhub.ndiOutputs.find(output => output.id === ownProps.outputId).status,
    config: state.config.NDIOutput[ownProps.outputId],
    lostStreamModes: getAvailableLostStreamModes(state),
    outputStandards: getAvailableNDIOutputStandards(state),
    forbiddenNames: existingNDIOutputNames.filter(name => name !== state.config.NDIOutput[ownProps.outputId].name),
    NDIAudioStandards : state.capabilities.NDIAudioStandard,
  }
};

export default connect(mapStateToProps)(withLocalize(GeneralForm));